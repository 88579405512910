<template>
<div class="page-container">
  <div class="takeUpRemaining">
    <router-view/>
  </div>
</div>
</template>

<script>
export default {
  name: 'Home',
  metaInfo: {
    title: 'Home'
  }
}
</script>
<style lang='scss' scoped>
.page-container{
  display: flex;
  height: calc(100vh - 4rem);
}
.takeUpRemaining{
  padding: 20px;
  width: 100%;
  background-color: var(--background-color-primary);
  overflow-y: scroll;
}
</style>
